import React from 'react';
import cn from 'classnames';

import Space from 'ui/space';
import Avatar from 'ui/avatar';
import Button from 'ui/button';

import AvatarImage from 'assets/images/avatar.svg';

type ManagerProps = {
    avatar?: string,
    name?: string,
    phone?: string,
};

export const Manager = (props: ManagerProps) => {
    const { name, phone, avatar } = props;

    let avatarImg;

    if (avatar) {
        try {
            new URL(avatar);
            avatarImg = avatar;
        } catch (_) {
            avatarImg = AvatarImage;
        }
    } else {
        avatarImg = AvatarImage;
    }

    return (
        <div className={ cn('manager') }>
            <Space gap={ 40 }>
                <div className={cn('manager-wrapper')}>
                    <div className={ cn('manager-avatar') }>
                        <Avatar src={ avatarImg } />
                    </div>
                    <div className={ cn('manager-info') }>
                        <div className={ cn('manager-name') }>{ name }</div>
                        <div className={ cn('manager-position') }>Сервис-менеджер</div>
                    </div>
                </div>
                <div className={cn('manager-connect')}>
                    <Button ghost link href={ `tel:${ phone }` } target="_self">Связаться с менеджером</Button>
                </div>
            </Space>
        </div>
    );
};
