import React from 'react';
import cn from 'classnames';

type ContentProps = {
    style?: object,
    children?: React.ReactNode,
};

const Content = ({ children, style }: ContentProps) => {
    return (
        <main className={ cn('layout-content') } style={ style }>{ children }</main>
    );
};

export default Content;
