
import * as Stomp from '@stomp/stompjs';
import SockJS from 'sockjs-client';

import {orderUpdateRecommendation, orderUpdateStatus} from './modules/order/action-creators';

const socketUrl = `${ process.env.REACT_APP_DOMAIN }/integration/ws/`;
let socket = new SockJS(socketUrl);
export let client: Stomp.CompatClient = Stomp.Stomp.over(socket);
client.debug = function () {
};

export default function StompMiddleware() {
    return (store: any) => (next: any) => (action: any) => {
        const stompFailure = (error: any) => {
            setTimeout(stompConnect, 3000);
        }

        const stompOnClose = () => {
            setTimeout(() => {
                socket = new SockJS(socketUrl);
                stompConnect();
            }, 3000);
        }

        const stompConnect = () => {
            client.connect(
                {
                    'Upgrade': 'websocket',
                },
                () => {
                    client.subscribe('/notification/landing', (message: any) => {
                        const notification = JSON.parse(message.body);
                        if (notification.eventType === "UpdateRecommendation") {
                            store.dispatch(orderUpdateRecommendation(notification));
                        } else if (notification.eventType === "OrderStatusChanged") {
                            store.dispatch(orderUpdateStatus(notification));
                        }
                    });

                },
                (error: any) => stompFailure(error),
                () => stompOnClose(),
            );
        };

        switch (action.type) {
            case 'STOMP_CONNECT':
                stompConnect();
                break;
            case 'LANDING_OPENED':
                client.send('/integration/send/notification', {}, JSON.stringify(action.payload));
                break;
            case 'WATCH_STARTED':
                client.send('/integration/send/notification', {}, JSON.stringify(action.payload));
                break;
            case 'WATCH_FINISHED':
                client.send('/integration/send/notification', {}, JSON.stringify(action.payload));
                break;
        }

        next({
            type: action.type,
            payload: action.payload,
        });
    };
}
