import React, { useState } from 'react';
import cn from 'classnames';

import CollapsePanel from 'ui/collapse/panel';
import { toArray } from 'utils/toArray';

type CollapseProps = {
    children: React.ReactNode,
    counter?: boolean,
    defaultActiveKeys?: React.Key | React.Key[],
} & typeof CollapseDefaultProps;

const CollapseDefaultProps = {
    counter: true,
};

const getActiveKeysArray = (key: React.Key | React.Key[]): React.Key[] => {
    let currentActiveKeys = key;

    if (!Array.isArray(currentActiveKeys)) {
        const activeKeyType = typeof currentActiveKeys;
        currentActiveKeys = activeKeyType === 'number' || activeKeyType === 'string' ? [currentActiveKeys] : [];
    }

    return currentActiveKeys.map((key) => String(key));
};

export const Collapse = ({ children, counter, defaultActiveKeys }: CollapseProps) => {
    const [activeKeys, setActiveKeys] = useState(getActiveKeysArray(defaultActiveKeys as React.Key[]));

    const onItemClick = (key: React.Key) => {
        const keys = [...activeKeys];
        const index = keys.indexOf(key);
        const isActive = index > -1;
        if (isActive) {
            keys.splice(index, 1);
        } else {
            keys.push(key);
        }

        setActiveKeys(keys);
    };

    const newPanel = (panel: any, index: any) => {
        const key = panel.key || String(index);
        const props = {
            key: key,
            panelKey: key,
            children: panel.props.children,
            title: panel.props.title,
            counter: counter,
            isActive: activeKeys.indexOf(key) > -1,
            onClick: onItemClick,
        };

        return React.cloneElement(panel, props);
    };

    return (
        <div className={ cn('collapse', { 'collapse-counter': counter }) }>
            { toArray(children).map(newPanel) }
        </div>
    );
};

Collapse.defaultProps = CollapseDefaultProps;
Collapse.Panel = CollapsePanel;
