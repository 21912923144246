import React, { InputHTMLAttributes } from 'react';
import cn from 'classnames';

interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

const InputDefaultProps = {};

export const Input = (props: InputProps): JSX.Element => {
    const { type, onChange, className } = props;

    const inputProps = {
        ...props,
        className: cn('input', { [`input-${ type }`]: type }, className),
        onChange: onChange,
    };

    return (
        <input
            { ...inputProps }
        />
    );
};

Input.defaultProps = InputDefaultProps;
