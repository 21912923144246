import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';

import { getOrders } from 'services/orders';

import { IOrderState } from 'store/modules/order/types';
import { orderSet } from 'store/modules/order/action-creators';

import Button from 'ui/button';
import Input from 'ui/input';
import Space from 'ui/space';
import Loader from 'ui/loader';
import useAuth from "../../../auth/useAuth";
import {Navigate, useNavigate} from 'react-router-dom';

export const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [localCode, setLocalCode] = useState('');
    const [error, setError] = useState('');
    const dispatch: Dispatch<any> = useDispatch();
    const auth = useAuth();
    const navigate = useNavigate();

    const setOrder = React.useCallback(
        (order: IOrderState) => dispatch(orderSet(order)),
        [dispatch],
    );

    const handleOnSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        fetchData();
    };

    const fetchData = useCallback(() => {
        setLoading(true);
        getOrders(localCode).then((response: AxiosResponse) => {
            setLoading(false);
            setOrder(response.data);
            auth.signIn(localCode, () => {
                navigate('/orders')
            })
        }).catch(() => {
            setTimeout(() => {
                setLoading(false);
                setError('Такого кода не существует');
            }, 300);
        })
    }, [auth, localCode, navigate, setOrder]);

    const handleOnChange = (event: React.FormEvent<HTMLInputElement>): void => {
        const { value, validity: { valid } } = event.currentTarget;

        if (!value) {
            setLocalCode(value);
        } else if (valid) {
            setLocalCode(value.toLocaleUpperCase());
        }

        setError('');
    };

    useEffect(() => {
        if (localCode.length === 4) {
            fetchData()
        }
    }, [localCode, fetchData]);

    if (auth.code) {
        return <Navigate to='/orders' />
    }

    return (
        <div className={ 'login-container' }>
            <Loader loading={ loading }>
                <form className={ 'login-form' } onSubmit={ handleOnSubmit }>
                    <Space gap={ 32 }>
                        <Input
                            inputMode={ 'decimal' }
                            placeholder={ 'Введите код из смс' }
                            className={ 'login-input' }
                            onChange={ handleOnChange }
                            value={ localCode }
                            pattern={ '[0-9]{0,4}' }
                            required
                        />
                        <Button styleType={ 'default' } type={ 'submit' }>Подтвердить</Button>
                        { error && (
                            <div className={ 'login-error' }>{ error }</div>
                        ) }
                    </Space>
                </form>
            </Loader>
        </div>
    );
};
