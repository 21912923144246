import React from 'react';
import cn from 'classnames';

import Header from './header';
import Content from './content';
import Footer from './footer';
import Section from './section';

interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode,
}

export const Layout = (props: LayoutProps) => {
    const { children } = props;

    const layoutProps = {
        ...props,
        className: cn('layout'),
    }

    return (
        <div { ...layoutProps }>{children}</div>
    );
};

Layout.Header = Header;
Layout.Content = Content;
Layout.Footer = Footer;
Layout.Section = Section;
