import React from 'react';
import cn from 'classnames';

import Space from 'ui/space';

// import { getICSFile } from 'services/orders';

import { IRecommendation } from 'domain/recommendation';
import Button from 'ui/button';

type CalendarListProps = {
    recommendation: IRecommendation,
    afterCalendarClick: () => void
};

export const CalendarList = (props: CalendarListProps) => {
    const { recommendation, afterCalendarClick } = props;

    const onGoogleClick = () => {
        const title = 'Запланировано ТО',
            details = recommendation.text,
            planDate = recommendation.planDate.toString().replaceAll('-', '').replaceAll(':', '');


        window.open(`https://calendar.google.com/calendar/r/eventedit?text=${ title }&dates=${ planDate }/${ planDate }&details=${ details }`, '_blank');
        afterCalendarClick();
    };

    const onAppleClick = () => {
        window.open(process.env.REACT_APP_DOMAIN + '/integration/client/orders/calendar/' + recommendation.id, '_blank');
        afterCalendarClick();
    };

    const devices = [
        { name: 'Apple', action: onAppleClick },
        { name: 'Google', action: onGoogleClick },
    ];

    return (
        <Space gap={ 24 }>
            <div className={ cn('calendar-title') }>Выберите календарь</div>
            <div className={ cn('calendar-list') }>
                <Space gap={8}>
                    { devices.map(device => (
                        <Button ghost onClick={device.action} key={device.name}>
                            <div className={'calendar-list-item'}>
                                <img
                                    className={ cn('calendar-list-icon') }
                                    src={ `/images/icons/${ device.name }.svg` }
                                    alt={ device.name }
                                />
                                <span className={ cn('calendar-list-name') }>{ device.name }</span>
                            </div>
                        </Button>
                    )) }
                </Space>
            </div>
        </Space>
    );
};
