import React from 'react';
import cn from 'classnames';

type AvatarProps = {
    src?: string,
} & typeof AvatarDefaultProps;

const AvatarDefaultProps = {
};

export const Avatar = (props: AvatarProps) => {
    const { src } = props;

    return (
        <div className={cn('avatar')}>
            <img src={src} alt={'Avatar'} />
        </div>
    );
};

Avatar.defaultProps = AvatarDefaultProps;
