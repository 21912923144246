import React from 'react';
import cn from 'classnames';
import {createPortal} from "react-dom";
import Button from "../button";
import { ReactComponent as Close } from 'assets/images/close.svg';

type ModalProps = {
    title?: string,
    src?: string,
    children?: React.ReactNode,
    isVisible: boolean,
    onOk?: () => void,
    onClose: () => void,
    withOptions?: boolean,
};

export const Modal = (props: ModalProps) => {
    const { title, src, children, isVisible, onOk, onClose, withOptions = false} = props;
    return createPortal(
        <div className={ cn('modal', { 'modal-is-visible': isVisible }) }>
            <div className={ cn('modal-content') }>
                <div className={ cn('modal-close')} onClick={onClose}><Close/></div>
                { withOptions && (
                    <>
                        <img className={ cn('modal-img')} src={src} alt={'cancel'}/>
                        <div className={ cn('modal-title')}>{title}</div>
                        <div className={ cn('modal-buttons')}>
                            <Button styleType={'secondary'} style={{ 'width': 144 }} onClick={onOk}>Отказаться</Button>
                            <Button style={{ 'width': 144 }} onClick={onClose}>Нет</Button>
                        </div>
                    </>
                )}

                { children }
            </div>
        </div>
    , document.body);
};
