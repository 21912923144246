import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducer';
import StompMiddleware from './stomp-middleware';

export default function configureStore() {
    const middlewares = [
        thunk,
        StompMiddleware(),
    ];

    return createStore(
        rootReducer,
        {},
        applyMiddleware(...middlewares),
    );
}
