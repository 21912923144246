import { IRecommendation } from 'domain/recommendation';
import { IAction } from 'store/types';
import { ORDER_SET, ORDER_UPDATE_RECOMMENDATION, ORDER_UPDATE_STATUS, ORDER_UPDATED } from './actions';
import { IOrderState } from 'store/modules/order/types';

export function orderSet(order: IOrderState): IAction {
    const data = {
        ...order,
        recommendations: order.recommendations.map((item) => {
            let counter: number = 0;

            return {
                ...item,
                fragments: item.fragments.map((item) => ({
                    ...item,
                    recommendations: item.recommendations.map((item) => {
                        counter = counter + 1;
                        return {
                            ...item,
                            title: `Рекомендация №${counter}`
                        };
                    }),
                })),
            }
        })
    }
    return {
        type: ORDER_SET,
        payload: data,
    };
}

export function orderUpdateRecommendation(recommendation: IRecommendation): IAction {
    return {
        type: ORDER_UPDATE_RECOMMENDATION,
        payload: recommendation,
    };
}

export function orderUpdateStatus(order: any): IAction {
    return {
        type: ORDER_UPDATE_STATUS,
        payload: order,
    };
}

export function orderUpdated(order: any) : IAction {
    return {
        type: ORDER_UPDATED,
        payload: order,
    };
}
