import React from 'react';
import cn from 'classnames';

type LoaderProps = {
    children: React.ReactNode,
    loading: boolean,
};

export const Loader = (props: LoaderProps) => {
    const { children, loading } = props;

    return (
        <div className={ cn('loader', { 'loader-is-loading': loading }) }>
            <div className={ cn('loader-content') }>
                <div className={ cn('loader-spinner') } />
            </div>
            { children }
        </div>
    );
};
