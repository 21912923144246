import { IStagedFragments } from 'domain/staged-fragments';
import { IAction } from 'store/types';
import { IOrderState } from './types';
import {ORDER_SET, ORDER_UPDATE_RECOMMENDATION, ORDER_UPDATE_STATUS} from './actions';

const initialState: IOrderState = {
    orderId: 0,
    orderNumber: '',
    managerName: '',
    managerContact: '',
    managerPhoto: '',
    clientName: '',
    vehicleModel: '',
    vehicleNumber: '',
    recommendations: [],
    promotions: [],
    orderStatus: 0,
};

const reducer = (
    state: IOrderState = initialState,
    action: IAction,
): IOrderState => {
    switch (action.type) {
        case ORDER_SET:
            return {
                ...state,
                ...action.payload,
            };
        case ORDER_UPDATE_RECOMMENDATION:
            const data = [...state.recommendations];
            data.forEach((stagedFragments: IStagedFragments, SFIndex: number) => {
                stagedFragments.fragments.forEach((fragment, FIndex) => {
                    fragment.recommendations.forEach((recommendation, RIndex) => {
                        if (recommendation.id === action.payload.recommendationId) {
                            data[SFIndex].fragments[FIndex].recommendations[RIndex] = {
                                ...recommendation,
                                statusId: action.payload.recommendationStatusId,
                                planDate: action.payload.planDate,
                            }
                        }
                    });
                });
            })
            return {
                ...state,
                recommendations: data,
            };
        case ORDER_UPDATE_STATUS:
            return {
                ...state,
                orderStatus: action.payload.orderStatusId,
            };
    }

    return state;
};

export default reducer;
