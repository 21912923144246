import React from 'react';

import Layout from 'ui/layout';
import Space from 'ui/space';
import Logo from 'ui/logo';

import useAuth from 'auth/useAuth';
import {useNavigate} from "react-router-dom";
import Button from 'ui/button';


type AppLayoutProps = {
    children: React.ReactNode,
};

export const AppLayout = ({ children }: AppLayoutProps) => {
    const mobileLogoSize = {
        width: 230,
        height: 30,
    };

    const desktopLogoSize = {
        width: 287,
        height: 40,
    };

    const auth = useAuth();
    const navigate = useNavigate();

    const handleLogOut = () => {
        auth.signOut(() => {
            navigate('/login')
        })
    }

    return (
        <Layout>
            <Layout.Header>
                <Logo mobileSize={mobileLogoSize} desktopSize={desktopLogoSize} />
            </Layout.Header>

            { children }

            <Layout.Footer>
                <Space gap={ 40 }>
                    <div style={ { textAlign: 'center' } }>
                        <Logo mobileSize={mobileLogoSize} desktopSize={desktopLogoSize} />
                    </div>

                    <div style={ { textAlign: 'center', color: '#666666', marginBottom: '20px'  } }>
                        <div>614064, г. Пермь, ул. Героев Хасана, 79</div>
                        <div>Телефон: <a href="tel:+7 (342) 264-28-80">+7 (342) 264-28-80</a></div>
                        <div>E-mail: <a href="mailto:cc@verra.ru">cc@verra.ru</a></div>
                    </div>
                    <div style={ { textAlign: 'center', color: '#666666'} }>
                        <Button ghost onClick={handleLogOut}>Выйти</Button>
                    </div>
                </Space>
            </Layout.Footer>
        </Layout>
    );
};
