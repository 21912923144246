import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { IRootReducer } from 'store/reducer';
import { IOrderState } from 'store/modules/order/types';

import Button from 'ui/button';
import Space from 'ui/space';
import Loader from 'ui/loader';

import { getOrders } from "services/orders";
import { AxiosResponse } from "axios";
import { orderSet } from "store/modules/order/action-creators";
import useAuth from "auth/useAuth";


export const GreetingPage = ({ onSkipBtnClick } : any) => {
    const order: IOrderState = useSelector(
        (state: IRootReducer) => state.order,
    );

    const [loading, setLoading] = useState<boolean>(false);
    const [opacity, setOpacity] = useState<number>(0)
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const dispatch = useDispatch();
    const auth = useAuth();
    const navigate = useNavigate();


    const setOrder = useCallback(
        (order: IOrderState) => dispatch(orderSet(order)),
        [dispatch],
    );

    useEffect(() => {
        const code = auth.code;
        if (code && !order.clientName) {
            setLoading(true);
            getOrders(code).then((response: AxiosResponse) => {
                setOpacity(1);
                setLoading(false);
                setOrder(response.data);
            }).catch(() => {
                auth.signOut(() => {
                    navigate('/login')
                });
            })
        } else {
            setOpacity(1);
        }
    }, [auth.code, order.clientName, setOrder, auth, navigate]);

    return (
        <div className={ 'greeting-container' }>
            <Loader loading={ loading }>
                <div className={ 'greeting-body' } style={{opacity: `${opacity}`}}>
                    <div className={ 'text-blocks' }>
                        <div className={ 'greeting-container-title' }>Добрый день, <br />{ order.clientName }!</div>
                        <Space gap={ isMobile ? 30 : 60 } divider>
                            <p>
                                Представляем полный Check UP здоровья Вашего автомобиля.
                                Мы провели видеодиагностику, и теперь все результаты и рекомендации перед Вами.
                            </p>
                            <p>
                                Нажимая кнопку «Далее», Вы просто ознакомитесь с видеодиагностикой Вашего автомобиля.
                            </p>
                        </Space>
                    </div>
                <Button styleType={ 'ghost' } onClick={ onSkipBtnClick }>Далее</Button>
                </div>
            </Loader>
        </div>
    );
};
