import { IStatus } from 'domain/status';
import { IAction } from 'store/types';
import { STATUSES_SET } from './actions';

export function statusesSet(order: IStatus[]): IAction {
    return {
        type: STATUSES_SET,
        payload: order,
    };
}
