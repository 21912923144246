import React from 'react';
import {Provider} from 'react-redux';
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';

import configureStore from 'store/configure-store';
import AuthProvider from 'auth/authProvider';
import RequireAuth from 'auth/RequireAuth';
import LoginLayout from "application/layouts/login";
import OrdersPage from "application/pages/orders";
import LoginPage from "application/pages/login";

const store = configureStore()

export const Application = () => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <AuthProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path={'/login'} element={<LoginLayout><LoginPage /></LoginLayout>} />
                            <Route path={'/orders'} element={<RequireAuth><OrdersPage /></RequireAuth>} />
                            <Route
                                path={'/'}
                                element={<RequireAuth><Navigate replace to="/orders" /></RequireAuth>}>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </AuthProvider>
            </Provider>
        </React.StrictMode>
    );
};
