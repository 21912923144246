import { combineReducers } from 'redux';
import { IOrderState } from 'store/modules/order/types';
import { IDictionariesState } from 'store/modules/dictionaries/types';
import order from './modules/order/reducer';
import dictionaries from './modules/dictionaries/reducer';

export interface IRootReducer {
    order: IOrderState,
    dictionaries: IDictionariesState,
}

export default combineReducers<IRootReducer>({
    order,
    dictionaries,
});
