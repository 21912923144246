import React from 'react';
import cn from 'classnames';

type FooterProps = {
    children?: React.ReactNode,
}

const Footer = ({children}: FooterProps) => {
    return (
        <footer className={cn('layout-footer')}>{children}</footer>
    );
};

export default Footer;
