import React, {useState} from 'react';
import Cookies from "js-cookie";

export const AuthContext = React.createContext<AuthContextType>({
    code: '',
    signIn: (newCode, callback) => {},
    signOut: (callback) => {},
});

export interface AuthContextType {
    code: string,
    signIn: (newCode: string, callback: () => void) => void,
    signOut: (callback: () => void) => void
}

type AuthProviderProps = {
    children: React.ReactNode,
};

export default function AuthProvider({ children }: AuthProviderProps) {

    function getCookie(cookieName: string) {
        const result = Cookies.get(cookieName);
        if ( result )
            return result;
        else
            return '';
    }

    const [code, setcode] = useState<string>(getCookie('code'));

    const signIn = (newCode: string, callback: () => void) => {
        setcode(newCode);
        Cookies.set('code', newCode, { path: '/' });
        callback();
    };

    const signOut = (callback: () => void) => {
        setcode('');
        Cookies.remove('code',  { path: '/' });
        callback();
    };

    let value: AuthContextType = { code, signIn, signOut };

    return <AuthContext.Provider value={ value }>{ children }</AuthContext.Provider>;
}
