import React from 'react';

import Layout from 'ui/layout';
import Logo from 'ui/logo';

type LoginLayoutProps = {
    children: React.ReactNode,
};

export const LoginLayout = ({ children }: LoginLayoutProps) => {
    const mobileLogoSize = {
        width: 230,
        height: 30,
    };

    const desktopLogoSize = {
        width: 287,
        height: 40,
    };

    return (
        <>
        <Layout>
            <Layout.Header>
                <Logo mobileSize={mobileLogoSize} desktopSize={desktopLogoSize} />
            </Layout.Header>

            <Layout.Content>
                { children }
            </Layout.Content>
        </Layout>
        </>
    );
};
