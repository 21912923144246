import React from 'react';
import cn from 'classnames';

type DividerProps = {
    direction?: string,
} & typeof DividerDefaultProps;

const DividerDefaultProps = {
    direction: 'vertical',
};

export const Divider = (props: DividerProps) => {
    const { direction } = props;

    const classname = cn('divider', `divider-${direction}`);

    return <div className={classname} />;
};

Divider.defaultProps = DividerDefaultProps;
