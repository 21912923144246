import api from 'services/api';
import { AxiosResponse } from 'axios';


const getOrders = (code: string): Promise<AxiosResponse> => {
    return api.post('/integration/client/orders/', { 'code': code });
};

const updateRecommendation = (data: any): Promise<AxiosResponse> => {
    return api.post('/integration/client/orders/update', data);
}

// const getICSFile = (recommendationId: number): Promise<AxiosResponse> => {
//     return api.post(`/integration/client/orders/calendar`, { 'recommendationId': recommendationId });
// };

export {
    getOrders,
    updateRecommendation,
    // getICSFile,
};
