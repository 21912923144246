import React from 'react';
import cn from 'classnames';

import Divider from 'ui/divider';

import { toArray } from 'utils/toArray';

type SpaceProps = {
    children: React.ReactNode | React.ReactNode[],
    direction?: string,
    gap?: string | number,
    divider?: boolean,
} & typeof SpaceDefaultProps;

const SpaceDefaultProps = {
    direction: 'vertical',
    gap: 60,
    divider: false,
};

export const Space = (props: SpaceProps) => {
    const { children, direction, gap, divider } = props;

    const classname = cn('space', `space-${ direction }`);

    const items = toArray(children).map((child, index) => {
        return (
            <React.Fragment key={ index }>
                { divider && index > 0 && <Divider direction={ direction } /> }
                { child }
            </React.Fragment>
        );
    });


    return (
        <div className={ classname } style={ { gap: gap } }>{ items }</div>
    );
};

Space.defaultProps = SpaceDefaultProps;
