import React from 'react';
import cn from 'classnames';

import Space from 'ui/space';

type OrderInfoProps = {
    number?: string,
    auto?: string,
    vinCode?: string,
    code?: string,
    status?: number,
};

export const OrderInfo = (props: OrderInfoProps) => {
    const { number, auto, vinCode, code, status } = props;
    return (
        <div className={ cn('order-info') }>
            <div className={ cn('order-info-titles') }>
                <Space gap={15}>
                    <div className={ cn('order-info-number') }>Заказ-наряд</div>
                    <Space gap={5}>
                        <div className={ cn('order-info-title') }>Автомобиль</div>
                        { vinCode && <div className={ cn('order-info-title') }>VIN-номер</div>}
                        <div className={ cn('order-info-title') }>Номер</div>
                        { status === 4 && <div className={ cn('order-info-title') }>Статус</div> }
                    </Space>
                </Space>
            </div>
            <div className={ cn('order-info-values') }>
                <Space gap={15}>
                    <div className={ cn('order-info-number-value') }>№{ number }</div>
                    <Space gap={5}>
                        <div className={ cn('order-info-value') }>{ auto }</div>
                        { vinCode && <div className={ cn('order-info-value') }>{ vinCode }</div> }
                        <div className={ cn('order-info-value') }>{ code }</div>
                        { status === 4 && <div className={ cn('order-info-status-value') }>Подтвержден</div> }
                    </Space>
                </Space>
            </div>
        </div>
    );
};
