import React from 'react';
import cn from 'classnames';

type HeaderProps = {
    children?: React.ReactNode,
};

const Header = ({children}: HeaderProps) => {
    return (
        <header className={cn('layout-header')}>{children}</header>
    );
};

export default Header;
