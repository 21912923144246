import React, { useState } from 'react';
import cn from 'classnames';
import { Button, DatePicker } from 'antd';
import { IRecommendation } from 'domain/recommendation';
import moment from 'moment';

type RecommendationProps = {
    item: IRecommendation,
    onCancelBtn: (recommendationItem: IRecommendation) => void,
    sendRecommendation: (item: IRecommendation, statusId: number, planDate?: string) => void,
    status?: string,
    children?: React.ReactNode,
    withDate: boolean,
} & typeof RecommendationItemDefaultProps;

const RecommendationItemDefaultProps = {
    status: 'default',
};

export const Recommendation = (props: RecommendationProps) => {
    const {item, onCancelBtn, sendRecommendation, status, children, withDate} = props;

    const [isPickerVisible, setPickerVisible] = useState<boolean>(false);
    const [newPlanDate, setNewPlanDate] = useState<string>('');

    const formatDate = (date: string) => {
        return date.split('T')[0].split('-').reverse().join('.');
    };

    return (
        <div className={cn('recommendation', `recommendation-${status}`)}>
            <div className={cn('recommendation-header')}>
                <div className={cn('recommendation-title', `recommendation-title-${status}`)}>
                    {item.title}
                </div>
            </div>
            <div className={cn('recommendation-content')}>
                <div className={cn('recommendation-text')}>{item.text}</div>

                {withDate && (
                    <>
                        <div className={cn('recommendation-status', 'planned', 'new')}>
                            <div className={cn('recommendation-info')}>
                                <div className={cn('status-title')}>
                                    Дата исполнения
                                </div>
                                <div className={cn('plan-date')}>
                                    {formatDate(newPlanDate) || formatDate(item.planDate)}
                                </div>
                            </div>
                            <>
                                <DatePicker
                                    open={isPickerVisible}
                                    onOpenChange={open => setPickerVisible(open)}
                                    style={{visibility: 'hidden', width: 0}}
                                    disabledDate={(current) => {
                                        return current && current < moment().subtract(1, 'days');
                                    }}
                                    onChange={(date) => {
                                        if (date) setNewPlanDate(date.format());
                                        setPickerVisible(false);
                                    }}
                                />
                                <Button type="text" style={{color: '#848484'}} onClick={() => setPickerVisible(true)}>
                                    Изменить дату
                                </Button>
                            </>
                        </div>
                        <div className={cn('recommendation-buttons')}>
                            <Button onClick={() => {
                                onCancelBtn(item);
                            }}>
                                <img alt={'Отказаться'} src={'/images/icons/CancelGray.svg'} />
                                <div>Отказаться</div>
                            </Button>
                            <Button onClick={() => sendRecommendation(item, 2, newPlanDate || item.planDate)}>
                                <img alt={'Подвердить'}
                                     src={'/images/icons/ApproveYellow.svg'} />
                                <div>Подтвердить</div>
                            </Button>
                        </div>
                    </>
                )}

                {children}
            </div>
        </div>
    );
};
