import api from 'services/api';
import { AxiosResponse } from 'axios';

const getStatuses = (): Promise<AxiosResponse> => {
    return api.get('/integration/mobile/api/statuses/recommendation');
};

export {
    getStatuses,
};
