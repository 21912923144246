import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>{
    text?: string;
    children?: React.ReactNode;
    onClick?: () => void;
    ghost?: boolean;
    styleType?: string;
    href?: string;
    link?: boolean;
    target?: string;
}

const ButtonDefaultProps = {
    styleType: 'default',
    ghost: false,
};

export const Button = (props: ButtonProps): JSX.Element => {
    const {
        style,
        text,
        children,
        onClick,
        ghost,
        type,
        styleType,
        href,
        link,
        target,
    } = props;

    const buttonProps = {
        className: cn('button', {
            'button-ghost': ghost,
            [`button-${styleType}`]: !ghost,
        }),
        onClick,
        type: type,
        style: style,
    };

    if (link) {
        return <a {...buttonProps} target={target} href={ href }>{children || text}</a>
    }

    return (
        <button {...buttonProps}>{children || text}</button>
    );
};

Button.defaultProps = ButtonDefaultProps;
