import React from 'react';
import cn from 'classnames';

type VideoProps = {
    autoPlay?: boolean,
    buffered?: TimeRanges,
    controls?: boolean,
    crossOrigin?: "" | "use-credentials" | "anonymous" | undefined,
    height: string | number,
    loop?: boolean,
    muted?: boolean,
    played?: TimeRanges,
    preload?: string,
    poster?: string,
    src: string,
    width: string | number,
    onPlay?: () => void,
    onEnded?: () => void,
} & typeof VideoDefaultProps;

const VideoDefaultProps = {
    autoPlay: false,
    controls: true,
    crossOrigin: 'use-credentials',
    loop: false,
    muted: false,
    preload: 'metadata',
};

export const Video = ({
  autoPlay,
  buffered,
  controls,
  crossOrigin,
  height,
  loop,
  muted,
  played,
  preload,
  poster,
  src,
  width,
  onPlay,
  onEnded,
}: VideoProps) => {
    const props = {
        autoPlay: autoPlay,
        buffered: buffered,
        controls: controls,
        crossOrigin: crossOrigin,
        height: height,
        loop: loop,
        muted: muted,
        played: played,
        preload: preload,
        poster: poster,
        width: width,
        className: cn('video'),
        onPlay: onPlay,
        onEnded: onEnded,
    };

    return (
        <video { ...props }>
            <source src={ src } type="video/mp4" />
        </video>
    );
};

Video.defaultProps = VideoDefaultProps;
