import React from 'react';

type SectionProps = {
    title?: string,
    children?: React.ReactNode,
    padding?: string | number,
} & typeof SectionDefaultProps;

const SectionDefaultProps = {
    padding: 15,
};

export const Section = (props: SectionProps) => {
    const { title, children, padding } = props;

    return (
        <section className="section">
            { title && (
                <div className="section-header">
                    <div className="section-title">{ title }</div>
                </div>
            ) }
            <div className="section-content" style={ { paddingLeft: padding, paddingRight: padding } }>
                { children }
            </div>
        </section>
    );
};

Section.defaultProps = SectionDefaultProps;
