import React from 'react';
import cn from 'classnames';

type CollapsePanelProps = {
    children: React.ReactNode,
    panelKey?: string | number,
    title: string,
    isActive?: boolean,
    counter?: boolean,
    onClick?: (panelKey: string | number) => void,
} & typeof CollapsePanelDefaultProps;

const CollapsePanelDefaultProps = {
    isActive: false,
    counter: true,
    onClick() {},
};

export const CollapsePanel = ({ children, panelKey, title, isActive, counter, onClick }: CollapsePanelProps) => {
    const onItemClick = () => {
        onClick(panelKey as React.Key);
    }

    return (
        <div className={cn('collapse-panel', {'collapse-panel-active': isActive})}>
            <div className={cn('collapse-panel-header')} onClick={onItemClick}>
                {counter && (
                    <div className={cn('collapse-panel-counter')}>
                        {panelKey}
                    </div>
                )}
                <div className={cn('collapse-panel-title')}>
                    {title}
                </div>
                <div className={cn('collapse-panel-arrow')} />
            </div>

            <div className={cn('collapse-panel-wrapper')}>
                <div className={cn('collapse-panel-content')}>
                    {children}
                </div>
            </div>
        </div>
    );
};

CollapsePanel.defaultProps = CollapsePanelDefaultProps;
