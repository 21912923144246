import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Image } from 'assets/images/logo.svg';

interface ILogoSize {
    width: number;
    height: number;
}

interface ILogoProps {
    mobileSize: ILogoSize;
    desktopSize: ILogoSize;
}

const Logo = (props: ILogoProps) => {
    const { mobileSize, desktopSize } = props;

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const logoWidth = isMobile ? mobileSize.width : desktopSize.width;
    const logoHeight = isMobile ? mobileSize.height : desktopSize.height;

    return <Image width={ logoWidth } height={ logoHeight } />;
};

export default Logo;
