import { IAction } from 'store/types';
import { IDictionariesState } from './types';
import { STATUSES_SET } from './actions';

const initialState: IDictionariesState = {
    statuses: [],
};

const reducer = (
    state: IDictionariesState = initialState,
    action: IAction,
): IDictionariesState => {
    switch (action.type) {
        case STATUSES_SET:
            return {
                ...state,
                statuses: action.payload,
            };
    }

    return state;
};

export default reducer;
