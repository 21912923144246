import useAuth from "./useAuth";
import {Navigate, useLocation} from 'react-router-dom';
import React from "react";

type RequireAuthProps = {
    children: React.ReactNode,
};

export default function RequireAuth({ children }: RequireAuthProps) {
    let auth = useAuth();
    let location = useLocation();

    if (!auth.code) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return <div>{children}</div>;
}
