import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';

import { IStatus } from 'domain/status';

import { statusesSet } from 'store/modules/dictionaries/action-creators';
import { getStatuses } from 'services/statuses';
import AppLayout from 'application/layouts/app';
import MainPage from 'application/pages/main';
import LoginLayout from "application/layouts/login";
import GreetingPage from "application/pages/greeting";

export const OrdersPage = () => {
    const dispatch: Dispatch<any> = useDispatch();

    const setStatuses = React.useCallback(
        (statuses: IStatus[]) => dispatch(statusesSet(statuses)),
        [dispatch],
    );

    const [isGreetingShowed, setGreetingShowed] = useState<boolean>(false);

    const onSkipBtnClick = () => {
        setGreetingShowed(true);
    };

    useEffect(() => {
        getStatuses().then((response) => {
            const { statuses } = response.data;
            setStatuses(statuses);
        });
    }, [setStatuses]);

    useEffect(() => {
        dispatch({ type: 'STOMP_CONNECT' });
    }, [dispatch]);

    return (
        <>
            { isGreetingShowed ? (
                <AppLayout>
                    <MainPage/>
                </AppLayout>
                ) :
                (
                <LoginLayout>
                    <GreetingPage onSkipBtnClick={ onSkipBtnClick }/>
                </LoginLayout>
            )}
        </>
    );
};
